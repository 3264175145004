class SavedListingRepository {
    private static instance: SavedListingRepository;

    private LOCAL_STORAGE_KEY = 'zidx.savedListings'

    private constructor() {
    }

    public static getInstance(): SavedListingRepository {
        if (!SavedListingRepository.instance) {
            SavedListingRepository.instance = new SavedListingRepository();
        }
        return SavedListingRepository.instance;
    }

    public save(listingId: string) {
        let listingIds = this.getListingIds();
        if (listingIds.indexOf(listingId) == -1) {
            listingIds.push(listingId);
            localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(listingIds));
        }
    }

    public remove(listingId: string) {
        let listingIds = this.getListingIds();
        let index = listingIds.indexOf(listingId);
        if (index != -1) {
            listingIds.splice(index, 1);
            localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(listingIds));
        }
    }

    public isSaved(listingId: string) {
        let listingIds = this.getListingIds();
        return listingIds.indexOf(listingId) != -1;
    }

    public getListingIds(): Array<string> {
        let value = localStorage.getItem(this.LOCAL_STORAGE_KEY);
        const listingIds = (value && JSON.parse(value)) || [];
        return listingIds;
    }
}